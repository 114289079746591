import React from "react";
import zxcvbn from "zxcvbn";

import Session from "../components/Session";
import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";
import Config from "../config";

import Select, { components, OptionProps } from "react-select";
import countryList from "react-select-country-list";
import "../assets/flag-css/flag-icons.min.css";
import { SparklesIcon } from "@heroicons/react/24/outline";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      errorDetails: false,
      success: false,
      username: "",
      password: "",
      fname: "",
      lname: "",
      company: "",
      jobTitle: "",
      phoneNumber: "",
      terms: false,
      passwordScore: 0,
    };
  }
  componentDidMount = async () => {
    document.title = `Register ${Config.titleSuffix}`;
  };
  async handleSubmit(e) {
    e.preventDefault();
    try {
      this.setState({ loading: true });
      if (!this.state.terms) {
        throw new Error("You must agree to the terms and conditions");
      }
      let options = {
        method: "POST",
        body: JSON.stringify({
          email: this.state.username,
          password: this.state.password,
          fname: this.state.fname,
          lname: this.state.lname,
          company: this.state.company,
          country: this.state.country,
          title: this.state.jobTitle,
          phone: this.state.phoneNumber,
          referrer: localStorage.getItem('referrerId')
        }),
      };
      let requestUrl = Config.api.root + "/auth/register";
      let response = await fetch(requestUrl, {
        ...options,
        ...Config.api.options,
      });
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (response.error) {
        throw response.error;
      }
      Session.setUserData(response);
      var _hsq = (window._hsq = window._hsq || []);
      _hsq.push(["identify", { email: response.user.username }]);
      console.log(response);
      this.props.updateOnboarding(1);
      this.setState({ loading: false });
    } catch (error) {
      this.setState({
        loading: false,
        error: true,
        errorDetails: {
          title: "Registration Failed",
          message:
            typeof error === "string"
              ? error
              : "We were unable to register your account. Please try again.",
        },
      });
    }
  }
  passwordChange(e) {
    var passwordValue = e.target.value;
    var passwordVerdict = zxcvbn(passwordValue).score + 1;
    this.setState({
      password: passwordValue,
      passwordScore: parseInt(passwordVerdict),
    });
  }
  render() {
    const passwordMeter = [1, 2, 3, 4, 5].map((i) => {
      let classes = "rounded-xl transition-colors h-2 ";

      if (i <= this.state.passwordScore && this.state.passwordScore <= 2) {
        classes += "bg-red-400";
      } else if (i <= this.state.passwordScore && this.state.passwordScore <= 4) {
        classes += "bg-yellow-400 ";
      } else if (i <= this.state.passwordScore && this.state.passwordScore === 5) {
        classes += "bg-green-500 ";
      } else {
        classes += "bg-gray-200 ";
      }

      return (
        <div className="w-1/5 px-1" key={i}>
          <div className={classes}></div>
        </div>
      );
    });

    const countryControl = ({ children, ...props }) => {
      let selectedOption = props.getValue();
      let classes =
        "fis rounded-full h-5 w-5 bg-center ml-2 mr-1 fi-" +
        selectedOption[0]?.value.toLowerCase();
      return (
        <components.Control {...props}>
          <div className={classes}></div>
          {children}
        </components.Control>
      );
    };
    const countryOption = (props) => {
      let selectedOption = props.data.value ? props.data.value.toLowerCase() : null;
      let classes = "fis rounded-full h-5 w-5 bg-center mr-2 fi-" + selectedOption;
      return (
        <components.Option {...props}>
          <div className={(props.isSelected ? "text-white " : "") + "flex items-center"}>
            <div className={classes}></div>
            {props.children}
          </div>
        </components.Option>
      );
    };

    return (
      <div className="flex flex-col items-center my-12 tall:my-36">
        <Message open={this.state.loading} type="loading" title="Creating account..." />
        <Modal
          open={this.state?.error}
          type="error"
          title={this.state.errorDetails.title}
          message={this.state.errorDetails.message}
          setClose={() => {
            this.setState({ error: false }),
              setTimeout(() => this.setState({ errorDetails: false }), 500);
          }}
        />

        <h2>Register for a free account</h2>
        <div className="flex items-center gap-5 p-4 m-4 border rounded-lg border-amber-200 bg-amber-100 text-amber-600">
          <SparklesIcon className="w-8 h-8 p-1 transition-colors rounded-full text-amber-600" />
          <div>
            <h3>Start now to activate your free trial of LinePilot Pro</h3>
            <p className="text-sm">
              No credit card required - after 14 days your account will revert to the free plan
            </p>
          </div>
        </div>

        <form
          id="linepilot-registration"
          className="max-w-lg"
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <div className="mt-4">
            <label htmlFor="email" className="block m-0 text-sm font-medium text-gray-700">
              Work Email
            </label>
            <div>
              <input
                type="email"
                value={this.state.username}
                onChange={(e) => this.setState({ username: e.target.value })}
                required
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>
          </div>
          <div className="flex">
            <div className="flex-1 mt-4 mr-4">
              <label htmlFor="fname" className="block m-0 text-sm font-medium text-gray-700">
                First Name
              </label>
              <div>
                <input
                  type="text"
                  required
                  value={this.state.fname}
                  onChange={(e) => this.setState({ fname: e.target.value })}
                  className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
            </div>

            <div className="flex-1 mt-4">
              <label htmlFor="lname" className="block m-0 text-sm font-medium text-gray-700">
                Last Name
              </label>
              <div>
                <input
                  type="text"
                  required
                  value={this.state.lname}
                  onChange={(e) => this.setState({ lname: e.target.value })}
                  className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="company" className="block m-0 text-sm font-medium text-gray-700">
              Company Name
            </label>
            <div>
              <input
                type="text"
                value={this.state.company}
                onChange={(e) => this.setState({ company: e.target.value })}
                required
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>
          </div>

          <div className="mt-4">
            <label htmlFor="country" className="block m-0 text-sm font-medium text-gray-700">
              Country
            </label>
            <div>
              <Select
                className="flex-1 text-sm"
                name="country"
                id="country"
                options={countryList().getData()}
                isClearable="true"
                components={{ Option: countryOption, Control: countryControl }}
                theme={Config.selectTheme}
                onChange={(selectedOption) => this.setState({ country: selectedOption?.value })}
              />
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="password" className="block m-0 text-sm font-medium text-gray-700">
              Create a Password
            </label>
            <div>
              <input
                name="password"
                //on-input('checkStrength')
                value={this.state.password}
                type="password"
                onChange={(e) => this.passwordChange(e)}
                required
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>
            <div className="flex mt-1 -mx-1">{passwordMeter}</div>
          </div>
          <label htmlFor="terms" className="flex items-center mt-4">
            <input
              type="checkbox"
              value={true}
              defaultChecked={this.state.terms}
              onChange={(e) => this.setState({ terms: e.target.value })}
              className="w-4 h-4 border-gray-300 rounded focus:ring-primary text-primary"
              required
            />
            <div className="ml-2 text-sm font-medium text-gray-700">
              I agree to the{" "}
              <a
                href="https://www.linepilot.co/terms-of-service"
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                Terms of Service
              </a>
            </div>
          </label>

          <div className="mt-6 text-center">
            <input
              className="button-primary"
              type="submit"
              value="Register"
              disabled={this.state.loading}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default Register;
