import React from "react";
import NumberDisplay from "./fields/NumberDisplay";
class PreviewLineItem extends React.Component {

  render() {

    const { line, currency } = this.props;

    // Remove any price values for product line items and any other properties that are not displayed elsewhere
    const additionalProperties = Object.keys(line).filter((p, index) => {
      if (p.startsWith("hs_price") && "hs_product_id" in line) {
        return false;
      }
      return !["name", "hs_sku", "price", "quantity", "linepilot_id","hs_position_on_quote"].includes(
        p
      );
    });

    return (
      <div className="flex flex-row w-full py-2 border-b border-gray-200 border-dashed">
        <div className="flex flex-row flex-1">
          <p className="w-24 text-sm text-gray-400">{line.hs_sku || ""}</p>
          <p className="text-sm font-medium text-gray-900">
            <div>
              {line.name} <span className="text-xs text-gray-400"> x {line.quantity || 0}</span>
            </div>
            {additionalProperties.length > 0 && (
              <div className="flex flex-col">
                {additionalProperties.map((p) => {
                  const propertyDefinition = this.props.lineItemPropertyDefinitions.find(
                    (i) => i.name === p
                  );
                  let value;
                  if(propertyDefinition?.options.length > 0) {
                    value = propertyDefinition?.options.find((o) => o.value === line[p])?.label;
                  } else if(propertyDefinition?.type === "date") {
                    value = new Date(line[p]).toLocaleDateString();
                  } else {
                    value = line[p];
                  }
                  return (
                    <span className="text-xs text-gray-400">
                      {propertyDefinition?.label}
                      {p === "hs_product_id" && "Product ID"}: {value}
                    </span>
                  );
                })}
              </div>
            )}
          </p>
        </div>
        <div className="flex-shrink min-w-36">
          <p className="text-sm text-gray-500">
            <NumberDisplay value={line.price} currency={currency} />
          </p>
        </div>
      </div>
    );
  }
}

export default PreviewLineItem;
