import React from "react";
import { Link } from "@reach/router";
import LineItemPropertyInput from "./LineItemPropertyInput";
import { Bars3Icon, PencilIcon, PlusIcon, TrashIcon, WrenchIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import Select, { components, OptionProps } from "react-select";
import Config from "../config";
import { ChevronDownIcon, ExclamationTriangleIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { ReactComponent as ProductIcon } from "../assets/images/icons8-product.svg";
import { CubeTransparentIcon, EyeIcon } from "@heroicons/react/20/solid";
import ConditionSelector from "./ConditionSelector";
import Modal from "./modals/Modal";

class LineItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addActiveProperty: false,
      loading: true,
      product: {},
      showCurrentValues: false
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    this._isMounted && (await this.getStatus());
    this.setState({ loading: false });
  };

  async getStatus() {
    try {
      console.log("getting product data");
      let requestUrl = Config.api.root + "/products/get/" + this.props.product;
      let response = await fetch(requestUrl, Config.api.options);
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      console.log(response);
      if (response.error) {
        throw response.error;
      }
      this._isMounted &&
        this.setState({
          ...response,
        });
    } catch (error) {
      this._isMounted &&
        this.setState({
          error: true,
          errorDetails: { title: "Could not fetch product", message: error },
          loading: false,
        });
    }
  }

  async updateProperty(property, value = false, type = false) {
    this.setState({ selectedValue: null });
    if (!property) return false;

    let newSchema = { ...this.props.schema };
    newSchema[property] = {
      value: value,
      type: type || this.props.lineItemPropertyDefinitions.find((i) => i.name === property).type,
      hubspotDefined: !!this.props.lineItemPropertyDefinitions.find((i) => i.name === property)
        ?.hubspotDefined,
    };

    await this.props.updateLineItem(this.props.id, "schema", newSchema);

    if (this.state.addActiveProperty) {
      this.setState({ addActiveProperty: false });
    }
  }

  async removeProperty(property) {
    let newSchema = { ...this.props.schema };
    delete newSchema[property];
    await this.props.updateLineItem(this.props.id, "schema", newSchema);
  }

  async addCondition(type) {
    let conditions = this.props?.conditions || [];
    let id = Math.random().toString(36).substring(7);
    conditions.push({
      id: id,
      type,
      property: "",
      value: "",
      operator: "equals",
    });
    await this.props.updateLineItem(this.props.id, "conditions", conditions);
  }
  
  async updateCondition(id, property, value, operator) {
    let conditions = this.props?.conditions || [];
    conditions = conditions.map((c) => {
      if (c.id === id) {
        c.property = property;
        c.value = value;
        c.operator = operator;
      }
      return c;
    });

    await this.props.updateLineItem(this.props.id, "conditions", conditions);
  }
  async removeCondition(id) {
    let conditions = this.props?.conditions || [];
    conditions = conditions.filter((c) => c.id !== id);
    await this.props.updateLineItem(this.props.id, "conditions", conditions);
  }
  
  async handleSave() {
    await this.props.saveTemplate();
    await this.props.updateLineItem(this.props.id, "isEditing", false);
  }

  toNumber(number) {
    try {
      const formattedNumber = new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 10,
      }).format(number);

      if (isNaN(formattedNumber)) {
        throw new Error("Not a number");
      }
      return formattedNumber;
    } catch (error) {
      return number;
    }
  }

  canUseCustomProperties = () => {
    return this.props?.account?.features?.customProperties;
  };

  render() {
    // remove null values from properties object
    const productInfo = this.state.product.properties;

    const dataEntry =
      !this.state.loading &&
      Object.keys(this.props.schema).map((p, index) => {
        let schemaProperty = this.props.schema[p];
        let lineItemPropertyDefinition = this.props.lineItemPropertyDefinitions.find(
          (i) => i.name === p
        );

        return (
          lineItemPropertyDefinition && (
            <LineItemPropertyInput
              data={schemaProperty}
              key={index}
              propertyDefinition={lineItemPropertyDefinition}
              dealPropertyDefinitions={this.props.dealPropertyDefinitions}
              updateProperty={this.updateProperty.bind(this)}
              removeProperty={this.removeProperty.bind(this)}
              required={false}
              isOverwritten={
                productInfo && Object.keys(productInfo).includes(p) && productInfo[p] !== null
              }
            />
          )
        );
      });

    const userCanUseCustomProperties = this.canUseCustomProperties();
    const hasCustomProperties =
      this.props.lineItemPropertyDefinitions.filter((p) => !p.hubspotDefined).length > 0;
    const showProMessage =
      hasCustomProperties && ["trial", "partner"].includes(this.props.user.plan);
    const showUpgradeMessage =
      hasCustomProperties && ["free", "starter"].includes(this.props.user.plan);

    const CustomOption = (props) => {
      return (
        <components.Option {...props}>
          <div className="flex items-center">
            {props.children}
            {props.data.proIcon ? (
              <SparklesIcon className="w-6 h-6 p-1 ml-5 mr-2 text-white rounded-full bg-amber-500" />
            ) : null}
          </div>
        </components.Option>
      );
    };

    function classNames(...classes) {
      return classes.filter(Boolean).join(" ");
    }
    const currentValues = (productInfo, props) => {
      return (
      <table
          className={classNames(
            "overflow-hidden block text-sm text-left divide-y divide-tone-500 w-full"
          )}
        >
          <tr>
            <th scope="col" className="flex-1 px-3 py-1 font-semibold text-left">
              Name
            </th>
            <th scope="col" className="px-3 py-1 font-semibold text-left ">
              Value
            </th>
          </tr>

          {Object.keys(productInfo).map((p, index) => {
            if (!productInfo[p] || productInfo[p] === "") return null;
            const isinSchema = Object.keys(props.schema).includes(p);
            const name = props.lineItemPropertyDefinitions.find(
              (i) => i.name === p
            )?.label;
            if (!name) return null;
            return (
              <tr className="text-sm">
                <td
                  className={classNames(isinSchema && "line-through", "px-3 py-1 ")}
                >
                  {name}
                </td>
                <td
                  className={classNames(isinSchema && "line-through", "px-3 py-1 ")}
                >
                  {productInfo[p]}
                </td>
              </tr>
            );
          })}
        </table>)
    }

    return (
      <div
        className={
          (this.props.isEditing && !this.state.loading && " flex-col p-0") + " card flex mb-2 "
        }
      >
        {!this.state.loading ? (
          <>
            {this.props.isEditing ? (
              <div className="flex flex-col divide-y">
                <div className="p-5">
                  <h3 className="flex items-center">
                    <Bars3Icon className="w-5 h-5 mr-3 -ml-3 text-stone-200" />
                    Edit Product
                  </h3>
                </div>

                <div className="relative flex flex-row items-center gap-4 px-10 py-5 group">
                  <ProductIcon className="absolute w-6 h-6 -ml-5 transition-all opacity-50 fill-primary group-hover:opacity-100" />
                  <div className="ml-5">
                    <h3>
                      Linked to product{" "}
                      {productInfo?.hs_sku && (
                        <span className="mr-2 text-xs text-toneDark">{productInfo.hs_sku}</span>
                      )}
                      <span className="font-medium">{productInfo?.name}</span>
                    </h3>
                    <p className="text-xs text-tone-700">
                      Product data will be pulled from the HubSpot product library when the template
                      is run.{" "}
                      <button
                        className="text-primary"
                        onClick={(e) => this.setState({ showCurrentValues: true })}
                      >
                        See current property values.
                      </button>
                    </p>

                    <Modal
                      open={this.state?.showCurrentValues}
                      title={productInfo?.name}
                      buttonText="Close"
                      message={currentValues(productInfo, this.props)}
                      setClose={() => this.setState({ showCurrentValues: false })}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-3 px-10 py-5 group">
                  <div className="flex flex-row items-center gap-4 -ml-5">
                    <CubeTransparentIcon className="w-6 h-6 transition-all opacity-50 text-primary group-hover:opacity-100" />
                    <div>
                      <h3>Properties</h3>
                      <p className="text-xs text-tone-700">
                        Set or override product properties for this line item.{" "}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-3 px-5">{dataEntry}</div>
                  <div className="relative flex flex-row flex-1 gap-4 px-5 items-left">
                    <Select
                      className="text-sm min-w-[200px] w-fit"
                      options={this.props.lineItemPropertyDefinitions
                        .map((p) => {
                          return {
                            label: p.label,
                            value: p.name,
                            isDisabled: !p.hubspotDefined && !userCanUseCustomProperties,
                            proIcon: !p.hubspotDefined && (showUpgradeMessage || showProMessage),
                          };
                        })
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .filter((p) => !this.props.schema[p.value])}
                      isClearable="true"
                      placeholder="Add property"
                      theme={Config.selectTheme}
                      value={this.state.selectedValue}
                      onClick={() => this.setState({ openedPropertiesSelect: true })}
                      onChange={(o) => this.updateProperty(o?.value)}
                      components={{
                        Option: (props) => {
                          return <CustomOption {...props}>{props.children}</CustomOption>;
                        },
                      }}
                    />
                    {showUpgradeMessage && (
                      <Link
                        to="/billing"
                        className="flex items-center gap-1 transition-colors text-amber-500 hover:text-amber-600 group"
                      >
                        <SparklesIcon className="w-6 h-6 p-1 mr-2 text-white transition-colors rounded-full bg-amber-500 group-hover:bg-amber-600" />
                        <span className="text-xs">
                          Upgrade to unlock custom line item properties
                        </span>
                      </Link>
                    )}
                    {showProMessage && (
                      <div className="flex items-center gap-1 transition-colors text-amber-500 hover:text-amber-600 group">
                        <SparklesIcon className="w-6 h-6 p-1 mr-2 text-white transition-colors rounded-full bg-amber-500" />
                        <span className="text-xs leading-none">
                          Custom line item properties
                          <br /> are a pro feature
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="px-10 py-5 group">
                  <div className="flex flex-row items-center gap-4 mb-2 -ml-5 ">
                    <EyeIcon className="w-6 h-6 transition-all opacity-50 text-primary group-hover:opacity-100" />
                    <div>
                      <h3>Conditions</h3>
                      <p className="text-xs text-tone-700">
                        Only show this line item if the following conditions are met...
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1 px-5 mt-2">
                    {this.props?.conditions &&
                      this.props.conditions.map((f, index) => {
                        return (
                          <ConditionSelector
                            data={f}
                            key={index}
                            index={index}
                            dealPropertyDefinitions={this.props.dealPropertyDefinitions}
                            lineItemPropertyDefinitions={this.props.lineItemPropertyDefinitions}
                            dealStages={this.props.dealStages}
                            updateFilter={this.updateCondition.bind(this)}
                            removeFilter={this.removeCondition.bind(this)}
                          />
                        );
                      })}
                  </div>
                  <div className="text-center">
                    <button
                      className="mt-2 text-sm button-low text-primary hover:text-primary-400"
                      onClick={(e) => {
                        this.addCondition("deal");
                      }}
                    >
                      <PlusIcon className="inline w-4 mr-1" />
                      Add Deal Condition
                    </button>

                    <button
                      className="mt-2 text-sm button-low text-primary hover:text-primary-400"
                      onClick={(e) => {
                        this.addCondition("line");
                      }}
                    >
                      <PlusIcon className="inline w-4 mr-1" />
                      Add Line Item Condition
                    </button>

                  </div>
                </div>

                <div className="flex flex-row justify-end p-5">
                  <div className="flex gap-2">
                    <button
                      className="button-text"
                      onClick={(e) => this.props.updateLineItem(this.props.id, "isEditing", false)}
                    >
                      Close
                    </button>
                    <button className="button-primary" onClick={(e) => this.props.saveTemplate()}>
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="relative flex flex-row items-center flex-1 gap-5 pl-5">
                <div className="absolute -left-3">
                  <Bars3Icon className="w-5 h-5 text-stone-200 " />
                </div>
                {!productInfo ? (
                  <div className="flex flex-row items-center w-full gap-3">
                    <ExclamationTriangleIcon className="w-5 h-5 stroke-red-600" /> Error - Product
                    not found
                  </div>
                ) : (
                  <>
                    {(this.props.schema?.hs_sku?.value ||
                      this.state.product.properties?.hs_sku) && (
                      <div className="text-xs text-toneDark">
                        {this.props.schema?.hs_sku?.value || this.state.product.properties?.hs_sku}
                      </div>
                    )}
                    <h3 className="flex-grow">
                      {this.props.schema?.name?.value || productInfo?.name}
                      <span className="text-xs text-toneDark">
                        {" "}
                        x {this.props.schema?.quantity?.value || 0}
                      </span>
                    </h3>
                    {this.props.schema?.price?.value && (
                      <div className="text-xs text-toneDark">
                        {this.toNumber(this.props.schema?.price?.value)}
                      </div>
                    )}
                  </>
                )}
                <div className="flex flex-shrink gap-2">
                  {productInfo && (
                    <button
                      className="button-icon"
                      onClick={(e) => this.props.updateLineItem(this.props.id, "isEditing", true)}
                    >
                      <WrenchIcon className="w-3 h-3" />
                    </button>
                  )}
                  <button
                    className="button-icon"
                    onClick={(e) => this.props.removeLineItem(this.props.id)}
                  >
                    <TrashIcon className="w-3 h-3" />
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="">
            <h3 className="flex items-center">
              <Bars3Icon className="w-5 h-5 mr-3 -ml-3 text-stone-200" />
              Loading...
            </h3>
          </div>
        )}
      </div>
    );
  }

}

export default LineItem;
